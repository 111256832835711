import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Banner4 from "../assets/banner4.jpg";
import Banner5 from "../assets/banner5.jpg";
import Banner6 from "../assets/carousel.jpg";

const CarouselImg = () => {
  return (
    <Carousel
      autoPlay={true}
      infiniteLoop={true}
      showThumbs={false}
      className="pt-1.5"
    >
      <div>
        <img src={Banner4} className="h-[470px] rounded-lg" />
        {/* <p className="legend">Legend 1</p> */}
      </div>
      <div>
        <img src={Banner5} className="h-[470px] rounded-lg" />
        {/* <p className="legend">Legend 2</p> */}
      </div>
      <div>
        <img src={Banner6} className="h-[470px] rounded-lg" />
        {/* <p className="legend">Legend 2</p> */}
      </div>
    </Carousel>
  );
};

export default CarouselImg;
