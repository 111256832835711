import React, { useEffect, useState } from "react";
import CarouselImg from "../components/CarouselImg";
import Category from "../components/Category";
import FeaturedProducts from "../components/FeaturedProducts";
import Banner1 from "../assets/banner-1.png";
import Banner2 from "../assets/banner-3.png";
import Banner3 from "../assets/banner-2.png";
import Banner4 from "../assets/banner-9-min.png";
import Icon1 from "../assets/icon-1.png";
import Icon2 from "../assets/icon-2.png";
import Icon3 from "../assets/icon-3.png";
import Icon4 from "../assets/icon-4.png";
import { Button, Toast } from "flowbite-react";
import { HiOutlineShoppingCart } from "react-icons/hi";

const Home = () => {
  const [cart, setCart] = useState([]);
  const [showToast, setShowToast] = useState(false);
  // Add product to the cart
  const addToCart = (product) => {
    setCart((prevCart) => {
      // Check if the product is already in the cart
      const existingProduct = prevCart.find((item) => item.id === product.id);

      if (existingProduct) {
        // Increase the quantity
        return prevCart.map((item) =>
          item.id === product.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        // Add new product to the cart
        return [...prevCart, { ...product, quantity: 1 }];
      }
    });
    setShowToast(true);
  };
  useEffect(() => {
    localStorage.setItem("items", JSON.stringify(cart));
    if (showToast) {
      setTimeout(() => {
        setShowToast(false);
      }, 3000);
    }
  }, [cart]);
  return (
    <div>
      <div className="px-5">
        <CarouselImg />
        <div className="grid grid-cols-3 gap-4 py-1.5">
          <div className="relative">
            <div className="absolute w-3/5 flex flex-col p-10 gap-4">
              <h4 className="font-semibold text-2xl">
                Everyday Fresh & <br /> Clean with Our <br /> Products
              </h4>
              <Button size="xs" gradientMonochrome="info" className="w-24">
                Shop now
              </Button>
            </div>
            <img src={Banner1} alt="" className="rounded-lg h-64 w-full" />
          </div>
          <div className="relative">
            <div className="absolute w-3/5 flex flex-col p-10 gap-4">
              <h4 className="font-semibold text-2xl">
                The best <br /> Products <br /> Online
              </h4>
              <Button size="xs" gradientMonochrome="info" className="w-24">
                Shop now
              </Button>
            </div>
            <img src={Banner2} alt="" className="rounded-lg h-64 w-full" />
          </div>
          <div className="relative">
            <div className="absolute w-3/5 flex flex-col p-10 gap-4">
              <h4 className="font-semibold text-2xl">
                Make your Breakfast <br /> Healthy and easy
              </h4>
              <Button size="xs" gradientMonochrome="info" className="w-24">
                Shop now
              </Button>
            </div>
            <img src={Banner3} alt="" className="rounded-lg h-64 w-full" />
          </div>
        </div>
        <Category />
        {/* <Popularproducts /> */}
        <FeaturedProducts addToCart={addToCart} />
        <div className="bg-green-100 pt-20 flex justify-between items-center px-16 rounded-lg mb-2.5">
          <div>
            <h2 className="text-4xl font-semibold">
              Stay home & get your daily <br /> needs from our shop
            </h2>
            <h1 className="py-1.5 font-bold text-base">
              Start Your Daily Shopping with{" "}
              <span className="text-green-700">VD Supermart</span>
            </h1>
            <Button gradientDuoTone="greenToBlue" className="px-16 my-3.5">
              Shop Now
            </Button>
          </div>
          <img src={Banner4} alt="" className="h-64" />
        </div>
        <div className="grid grid-cols-4 gap-4 pt-1.5 pb-3.5">
          <div className="flex bg-slate-200 items-center p-5 rounded-xl">
            <img src={Icon1} alt="" className="rounded-lg w-14 h-14" />
            <div className="flex flex-col px-5">
              <h4 className="font-semibold text-xl">Best prices & offers</h4>
              <h4 className="font-semibold">Orders ₹ 599 or more</h4>
            </div>
          </div>
          <div className="flex bg-slate-200 items-center p-5 rounded-xl">
            <img src={Icon2} alt="" className="rounded-lg w-14 h-14" />
            <div className="flex flex-col px-5">
              <h4 className="font-semibold text-xl">Free delivery</h4>
              <h4 className="font-semibold">24/7 amazing services</h4>
            </div>
          </div>
          <div className="flex bg-slate-200 items-center p-5 rounded-xl">
            <img src={Icon3} alt="" className="rounded-lg w-14 h-14" />
            <div className="flex flex-col px-5">
              <h4 className="font-semibold text-xl">Great daily deals</h4>
              <h4 className="font-semibold">When you sign up</h4>
            </div>
          </div>
          <div className="flex bg-slate-200 items-center p-5 rounded-xl">
            <img src={Icon4} alt="" className="rounded-lg w-14 h-14" />
            <div className="flex flex-col px-5">
              <h4 className="font-semibold text-xl">Wide assortment</h4>
              <h4 className="font-semibold">Mega Discounts</h4>
            </div>
          </div>
        </div>
        {/* <Cart cart={cart} /> */}
      </div>

      {showToast && (
        <Toast className="fixed bottom-5 left-5 bg-yellow-300">
          <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-cyan-100 text-cyan-500 dark:bg-cyan-800 dark:text-cyan-200">
            <HiOutlineShoppingCart className="h-5 w-5" />
          </div>
          <div className="ml-3 text-sm font-semibold text-black">
            Item added to cart successfully!
          </div>
          <Toast.Toggle onDismiss={() => setShowToast(false)} />
        </Toast>
      )}
    </div>
  );
};

export default Home;
