import React, { useState } from "react";
import CartImg from "../assets/banner.png";
import { Button, Radio, Label, TextInput, Checkbox } from "flowbite-react";
import { useNavigate } from "react-router";

const Placeorder = () => {
  const navigate = useNavigate();
  const cart = JSON.parse(localStorage.getItem("items"));
  const [address, setAddress] = useState({
    name: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    nearby: "",
    location: "",
    default: false,
  });
  // Calculate total price
  // console.log(cart);
  const total = cart.reduce(
    (sum, product) => sum + product.price * product.quantity,
    0
  );
  const mrptotal = cart.reduce(
    (sum, product) => sum + product.mrp * product.quantity,
    0
  );

  const placeOrderHandler = () => {
    if (
      address.name === "" ||
      address.phone === "" ||
      address.address === "" ||
      address.city === "" ||
      address.state === "" ||
      address.pincode === ""
    ) {
      alert("Please fill address details!");
    } else {
      navigate("/thankyou");
    }
  };
  return (
    <div className="pb-5 px-5">
      <img src={CartImg} alt="" className="w-full rounded-lg opacity-80" />
      <div className="grid grid-cols-3 gap-3 py-2.5">
        <div className="col-span-2">
          <h1>Billing Details</h1>
          <form className="flex w-full flex-col gap-4">
            <div className="grid grid-cols-2 gap-2">
              <div>
                <div className="mb-0.5 block">
                  <Label htmlFor="fname" value="Fullname" />
                </div>
                <TextInput
                  id="fname"
                  type="text"
                  sizing="sm"
                  value={address.name}
                  onChange={(e) =>
                    setAddress({ ...address, name: e.target.value })
                  }
                  placeholder="Fullname"
                  required
                />
              </div>
              <div>
                <div className="mb-0.5 block">
                  <Label htmlFor="phone" value="Phone no" />
                </div>
                <TextInput
                  id="phone"
                  type="mobile"
                  sizing="sm"
                  value={address.phone}
                  onChange={(e) =>
                    setAddress({ ...address, phone: e.target.value })
                  }
                  placeholder="Phone no"
                  required
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <div>
                <div className="mb-0.5 block">
                  <Label htmlFor="address" value="Address" />
                </div>
                <TextInput
                  id="address"
                  type="text"
                  sizing="sm"
                  value={address.address}
                  onChange={(e) =>
                    setAddress({ ...address, address: e.target.value })
                  }
                  placeholder="Address"
                  required
                />
              </div>
              <div>
                <div className="mb-0.5 block">
                  <Label htmlFor="city" value="City" />
                </div>
                <TextInput
                  id="city"
                  type="text"
                  value={address.city}
                  onChange={(e) =>
                    setAddress({ ...address, city: e.target.value })
                  }
                  sizing="sm"
                  placeholder="City"
                  required
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <div>
                <div className="mb-0.5 block">
                  <Label htmlFor="state" value="State" />
                </div>
                <TextInput
                  id="state"
                  type="text"
                  sizing="sm"
                  value={address.state}
                  onChange={(e) =>
                    setAddress({ ...address, state: e.target.value })
                  }
                  placeholder="State"
                  required
                />
              </div>
              <div>
                <div className="mb-0.5 block">
                  <Label htmlFor="Pincode" value="Pincode" />
                </div>
                <TextInput
                  id="Pincode"
                  type="text"
                  sizing="sm"
                  value={address.pincode}
                  onChange={(e) =>
                    setAddress({ ...address, pincode: e.target.value })
                  }
                  placeholder="Pincode"
                  required
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <div>
                <div className="mb-0.5 block">
                  <Label htmlFor="near" value="Near by" />
                </div>
                <TextInput
                  id="near"
                  type="text"
                  sizing="sm"
                  value={address.nearby}
                  onChange={(e) =>
                    setAddress({ ...address, nearby: e.target.value })
                  }
                  placeholder="Near by"
                  required
                />
              </div>
              <div className="flex justify-between items-center">
                <fieldset className="flex max-w-md flex-col gap-4">
                  <legend className="mb-2">Type of address</legend>
                  <div className="flex items-center gap-3.5">
                    <div className="flex items-center gap-2">
                      <Radio
                        id="home"
                        name="location"
                        value={
                          address.location === "" ? "Home" : address.location
                        }
                        onChange={(e) =>
                          setAddress({ ...address, location: e.target.value })
                        }
                        defaultChecked
                      />
                      <Label htmlFor="home">Home</Label>
                    </div>
                    <div className="flex items-center gap-2">
                      <Radio
                        id="work"
                        name="location"
                        value={
                          address.location === "" ? "Work" : address.location
                        }
                        onChange={(e) =>
                          setAddress({ ...address, location: e.target.value })
                        }
                      />
                      <Label htmlFor="work">Work</Label>
                    </div>
                    <div className="flex items-center gap-2 px-10">
                      <Checkbox
                        id="accept"
                        value={address.default}
                        onChange={(e) =>
                          setAddress({ ...address, default: !address.default })
                        }
                      />
                      <Label htmlFor="accept" className="flex">
                        Default
                      </Label>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </form>
        </div>
        <div className="px-8 bg-slate-100 rounded-lg py-2.5">
          <h1 className="text-lg font-bold">Your order</h1>
          <div className="flex justify-between items-center py-0.5">
            <h1>Total Price ({cart.length} items)</h1>
            <h1 className="font-medium">₹ {mrptotal.toFixed(2)}</h1>
          </div>
          <div className="flex justify-between items-center py-0.5">
            <h1>Discount</h1>
            <h1 className="font-semibold text-green-600">
              -₹ {(mrptotal - total).toFixed(2)}
            </h1>
          </div>
          <div className="flex justify-between items-center py-0.5">
            <h1>Subtotal</h1>
            <h1 className="font-medium">₹ {total.toFixed(2)}</h1>
          </div>
          <div className="flex justify-between items-center py-0.5">
            <h1>Delivery charges</h1>
            <h1 className="font-medium text-green-600">FREE Delivery</h1>
          </div>
          <div className="flex justify-between items-center py-0.5">
            <h1>Handling charges</h1>
            <h1 className="font-medium ">₹ 6</h1>
          </div>
          <div className="border-b"></div>
          <div className="flex justify-between items-center py-0.5">
            <h1 className="font-bold">Amount Payable</h1>
            <h1 className="font-bold">₹ {Number(total.toFixed(2)) + 6}</h1>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-xs text-green-700 font-medium">
              Total Savings ₹ {(mrptotal - total).toFixed(2)} on this order
            </p>
          </div>
          <div className="border-b py-1.5"></div>
          <div className="flex gap-1 items-center py-3.5">
            <TextInput
              id="small"
              type="text"
              sizing="sm"
              className="w-full"
              placeholder="Enter promo code"
            />
            <Button
              gradientDuoTone="pinkToOrange"
              size="xs"
              className="py-1 px-2.5"
            >
              Apply
            </Button>
          </div>
          <div className="border-b py-1.5"></div>
          <fieldset className="flex max-w-md flex-col gap-4 py-3.5">
            {/* <legend className="mb-0">Select payment method</legend> */}
            <div className="flex items-center gap-2">
              <Radio id="cash" name="payment" value="Cash" defaultChecked />
              <Label htmlFor="cash">Cash On Delivery</Label>
            </div>
            <div className="flex items-center gap-2 pb-2.5">
              <Radio id="online" name="payment" value="Online" disabled />
              <Label htmlFor="online" disabled>
                Credit / Debit / UPI / Net Banking / Wallets
              </Label>
            </div>
          </fieldset>
          <Button
            gradientMonochrome="success"
            className="w-full"
            onClick={placeOrderHandler}
          >
            Place Order & Pay
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Placeorder;
