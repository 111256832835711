import React from "react";

const Products = () => {
  return (
    <div>
      <h1>Products</h1>
    </div>
  );
};

export default Products;
