import React from "react";
import { Button, Table } from "flowbite-react";
import { HiOutlinePlusSm, HiMinusSm, HiOutlineTrash } from "react-icons/hi";
import CartImg from "../assets/banner.png";
import { useNavigate } from "react-router";

function Cart() {
  const navigate = useNavigate();
  const cart = JSON.parse(localStorage.getItem("items"));
  const user = localStorage.getItem("user");
  // Calculate total price
  // console.log(cart);
  const total = cart.reduce(
    (sum, product) => sum + product.price * product.quantity,
    0
  );
  const mrptotal = cart.reduce(
    (sum, product) => sum + product.mrp * product.quantity,
    0
  );

  return (
    <div className="pb-5 px-5">
      {/* <h2 className="text-lg font-semibold absolute text-black">
        Shopping Cart
      </h2> */}
      <img src={CartImg} alt="" className="w-full rounded-lg opacity-80" />
      {cart.length === 0 ? (
        <p>Your cart is empty</p>
      ) : (
        <div className="grid grid-cols-3 gap-3 py-2.5">
          <div className="col-span-2">
            <Table>
              <Table.Head>
                <Table.HeadCell>Product name</Table.HeadCell>
                <Table.HeadCell>MRP</Table.HeadCell>
                <Table.HeadCell>Price</Table.HeadCell>
                <Table.HeadCell>Quantity</Table.HeadCell>
                <Table.HeadCell>Subtotal</Table.HeadCell>
                <Table.HeadCell>
                  <span className="sr-only">Edit</span>
                </Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                {cart.map((item) => (
                  <Table.Row
                    key={item.id}
                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                  >
                    <Table.Cell className="flex gap-2 items-center whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      <img
                        src={item.image}
                        alt=""
                        className="w-8 h-8 rounded-md"
                      />
                      {item.name}
                    </Table.Cell>
                    <Table.Cell>₹ {item.mrp}</Table.Cell>
                    <Table.Cell>₹ {item.price}</Table.Cell>
                    <Table.Cell>
                      <div className="flex items-center gap-2">
                        <HiOutlinePlusSm className="w-5 h-5 bg-slate-200 rounded-md cursor-pointer p-0.5" />
                        {item.quantity}
                        <HiMinusSm className="w-5 h-5 bg-slate-200 rounded-md cursor-pointer p-0.5" />
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      ₹ {(item.price * item.quantity).toFixed(2)}
                    </Table.Cell>
                    <Table.Cell>
                      <HiOutlineTrash className="w-6 h-6 text-red-500 cursor-pointer" />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
          <div className="px-8 bg-slate-100 rounded-lg py-2.5">
            <h1 className="text-lg font-bold">Cart totals</h1>
            <div className="flex justify-between items-center py-1.5">
              <h1>Total Price ({cart.length} items)</h1>
              <h1 className="font-medium">₹ {mrptotal.toFixed(2)}</h1>
            </div>
            <div className="flex justify-between items-center py-1.5">
              <h1>Discount</h1>
              <h1 className="font-semibold text-green-600">
                -₹ {(mrptotal - total).toFixed(2)}
              </h1>
            </div>
            <div className="flex justify-between items-center py-1.5">
              <h1>Subtotal</h1>
              <h1 className="font-medium">₹ {total.toFixed(2)}</h1>
            </div>
            <div className="flex justify-between items-center py-1.5">
              <h1>Shipping</h1>
              <h1 className="font-medium">₹ 0</h1>
            </div>
            <div className="border-b"></div>
            <div className="flex justify-between items-center py-1.5">
              <h1 className="font-bold">Total Amount</h1>
              <h1 className="font-bold">₹ {total.toFixed(2)}</h1>
            </div>
            <Button
              gradientDuoTone="greenToBlue"
              className="w-full my-2.5"
              onClick={() =>
                user ? navigate("/placeorder") : navigate("/login")
              }
            >
              Proceed to checkout
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    margin: "20px auto",
    width: "50%",
    border: "1px solid #ccc",
    padding: "20px",
    borderRadius: "5px",
    boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
  },
  item: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  total: { marginTop: "20px" },
};

export default Cart;
