import logo from "./logo.svg";
import "./App.css";
import Layout from "./components/Layout";
import { Route, Routes } from "react-router";
import Home from "./pages/Home";
import Aboutus from "./pages/Aboutus";
import Products from "./pages/Products";
import Contact from "./pages/Contact";
import Termsofuse from "./pages/Termsofuse";
import Privacy from "./pages/Privacy";
import Refund from "./pages/Refund";
import Shipping from "./pages/Shipping";
import Cart from "./components/Cart";
import Placeorder from "./pages/Placeorder";
import AuthenticationLayout from "./pages/AuthenticationLayout";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Thankyou from "./pages/Thankyou";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/about" element={<Aboutus />} />
        <Route path="/products" element={<Products />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/placeorder" element={<Placeorder />} />
        <Route path="/thankyou" element={<Thankyou />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/termsofuse" element={<Termsofuse />} />
        <Route path="/privacypolicy" element={<Privacy />} />
        <Route path="/refundpolicy" element={<Refund />} />
        <Route path="/shippingpolicy" element={<Shipping />} />
      </Route>
      <Route element={<AuthenticationLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
      </Route>
    </Routes>
  );
}

export default App;
