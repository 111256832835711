import React from "react";
import VegetablesImg from "../assets/vegetable.png";

import { Card } from "flowbite-react";
// import { Card, CardBody, CardFooter, Image } from "@nextui-org/react";

const Category = () => {
  const categories = [
    {
      id: 1,
      title: "Vegetables",
    },
    {
      id: 2,
      title: "Fruits",
    },
    {
      id: 3,
      title: "Dals & Pulses",
    },
    {
      id: 4,
      title: "Masalas & Spices",
    },
    {
      id: 5,
      title: "Rice & Rice Products",
    },
    {
      id: 6,
      title: "Atta, Flours & Sooji",
    },
    {
      id: 7,
      title: "Salt, Sugar & Jaggery",
    },
    {
      id: 8,
      title: "Edible Oils & Ghee",
    },
    {
      id: 9,
      title: "Dry Fruits",
    },
    {
      id: 10,
      title: "Cuts & Sprouts",
    },
  ];
  return (
    <div>
      <h1 className="text-green-800 font-bold text-xl py-1.5">
        Shop by Category
      </h1>
      <div className="grid grid-cols-10 gap-1">
        {/* <img src={VegetablesImg} className="w-12 h-12" /> */}
        {categories.map((item) => {
          return (
            <div
              key={item.id}
              className="w-36 bg-slate-100 rounded-lg py-1.5 mb-2.5"
            >
              {/* <CardBody className="overflow-visible p-0 justify-center items-center">
                  <Image
                    radius="lg"
                    width="100%"
                    className="w-12 h-12 bg-none border-none"
                    src={VegetablesImg}
                  />
                </CardBody> */}
              <div className="overflow-visible p-0 justify-center items-center">
                <div className="flex justify-center items-center">
                  <img src={VegetablesImg} alt="" className="w-10 h-10" />
                </div>
                <h2 className="text-small text-center px-1 mx-0 py-1">
                  <span className="text-sm font-semibold">{item.title}</span>
                </h2>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Category;
