import React from "react";
import { BsBagPlusFill } from "react-icons/bs";

const products = [
  {
    id: 1,
    name: "Baby Apple Shimla, 1 kg",
    mrp: "221.92",
    price: "135",
    discount: "39",
    image:
      "https://www.bigbasket.com/media/uploads/p/m/40134281_22-fresho-baby-apple-shimla.jpg?tr=w-154,q-80",
  },
  {
    id: 2,
    name: "Banana - Robusta, 1 kg",
    mrp: "57.53",
    price: "35",
    discount: "39",
    image:
      "https://www.bigbasket.com/media/uploads/p/m/10000025_32-fresho-banana-robusta.jpg?tr=w-154,q-80",
  },
  {
    id: 3,
    name: "Carrot - Orange (Loose), 1 kg",
    mrp: "104.11",
    price: "43.5",
    discount: "58",
    image:
      "https://www.bigbasket.com/media/uploads/p/m/10000072_17-fresho-carrot-orange.jpg?tr=w-154,q-80",
  },
  {
    id: 4,
    name: "Tomato - Local (Loose), 1 kg",
    mrp: "75.34",
    price: "56",
    discount: "26",
    image:
      "https://www.bigbasket.com/media/uploads/p/m/10000203_18-fresho-tomato-local.jpg?tr=w-154,q-80",
  },
  {
    id: 5,
    name: "Onion (Loose), 1 kg",
    mrp: "84.93",
    price: "67",
    discount: "21",
    image:
      "https://www.bigbasket.com/media/uploads/p/l/10000148_33-fresho-onion.jpg",
  },
  {
    id: 6,
    name: "Potato, 1 kg",
    mrp: "73.97",
    price: "49",
    discount: "34",
    image:
      "https://www.bigbasket.com/media/uploads/p/l/10000159_28-fresho-potato.jpg",
  },
];

function FeaturedProducts({ addToCart }) {
  return (
    <div style={styles.container}>
      <div style={styles.grid}>
        {products.map((product) => (
          <div key={product.id} style={styles.card}>
            <img src={product.image} className="w-full h-40" />
            <div className="px-1">
              <p className="text-medium">{product.name}</p>
              <div className="flex gap-2 items-center">
                <p className="line-through text-sm">₹{product.mrp}</p>
                <p className="text-sm text-green-600">
                  {product.discount}% Off
                </p>
              </div>
              <div className="flex justify-between items-center">
                <h4 className="text-base font-bold">₹ {product.price}</h4>
                <button
                  onClick={() => addToCart(product)}
                  className="bg-green-600 px-3.5 py-2.5 rounded-lg"
                >
                  <BsBagPlusFill className="text-slate-50" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

const styles = {
  container: { padding: "0px 20px 20px 0px" },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(6, 1fr)",
    gap: "20px",
    marginTop: "0",
  },
  card: {
    padding: "2px 2px 5px 2px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
  },
};

export default FeaturedProducts;
