import React from "react";
import { Footer } from "flowbite-react";
import {
  BsDribbble,
  BsFacebook,
  BsGithub,
  BsInstagram,
  BsTwitter,
} from "react-icons/bs";
import Logo from "../assets/logo.png";
import Download from "../assets/play.png";

const Footerv = () => {
  return (
    <Footer container className="bg-[#fdf0d5]">
      <div className="">
        <div className="grid grid-cols-2 gap-8 justify-between">
          <div>
            <Footer.Brand href="/" src={Logo} alt="VD Logo" />
            <p className="text-justify">
              VD Supermart is a modern grocery store dedicated to providing
              customers with a comprehensive range of high-quality household and
              food products. Offers a diverse range of groceries, including
              staples like rice, wheat, pulses, and spices. Ensures that all
              products meet high standards of quality and hygiene. Provides a
              one-stop solution for daily and monthly household needs, ensuring
              ease and convenience for shoppers.
            </p>
            <p>Contact at: +040 4505 8811</p>
            <p>Email us: sales@vdsupermart.in</p>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:mt-4 sm:grid-cols-3 sm:gap-6">
            <div>
              <Footer.Title title="about" />
              <Footer.LinkGroup col>
                <Footer.Link href="#">Fruits & Vegetables</Footer.Link>
                <Footer.Link href="#">Foodgrains, Oil & Masala</Footer.Link>
                <Footer.Link href="#">About Us</Footer.Link>
                <Footer.Link href="#">Contact Us</Footer.Link>
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title title="Legal" />
              <Footer.LinkGroup col>
                <Footer.Link href="/privacypolicy">Privacy Policy</Footer.Link>
                <Footer.Link href="/termsofuse">
                  Terms &amp; Conditions
                </Footer.Link>
                <Footer.Link href="/refundpolicy">
                  Refund &amp; Return Policy
                </Footer.Link>
                <Footer.Link href="/shippingpolicy">
                  Shipping &amp; Delivery Policy
                </Footer.Link>
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title title="Download" />
              <Footer.LinkGroup col>
                <Footer.Link href="#" className="pl-0 ml-0">
                  <img src={Download} />
                </Footer.Link>
              </Footer.LinkGroup>
            </div>
          </div>
        </div>
        <Footer.Divider />
        <div className="w-full sm:flex sm:items-center sm:justify-between">
          <Footer.Copyright href="#" by="VD Supermart" year={2024} />
          <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
            <Footer.Icon href="#" icon={BsFacebook} />
            <Footer.Icon href="#" icon={BsInstagram} />
            <Footer.Icon href="#" icon={BsTwitter} />
            <Footer.Icon href="#" icon={BsGithub} />
            <Footer.Icon href="#" icon={BsDribbble} />
          </div>
        </div>
      </div>
    </Footer>
  );
};

export default Footerv;
