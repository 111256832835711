// import {
//   Navbar,
//   NavbarBrand,
//   NavbarContent,
//   NavbarItem,
//   Link,
//   DropdownItem,
//   DropdownTrigger,
//   Dropdown,
//   DropdownMenu,
//   Avatar,
//   Button,
//   Modal,
//   ModalContent,
//   ModalHeader,
//   ModalBody,
//   ModalFooter,
//   useDisclosure,
//   Checkbox,
//   Input,
//   Badge,
// } from "@nextui-org/react";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router";
import { BsCaretDownFill, BsCartFill } from "react-icons/bs";
import { Avatar, Badge, Button, Dropdown, Navbar } from "flowbite-react";

const Header = () => {
  const navigation = useNavigate();
  const loggedIn = localStorage.getItem("user");
  const [user, setUser] = useState(loggedIn);
  const [isInvisible, setIsInvisible] = useState(false);
  const cart = JSON.parse(localStorage.getItem("items"));
  const [login, setLogin] = useState(false);

  const logoutHandler = (e) => {
    // e.preventDefault();
    localStorage.removeItem("user");
    navigation("/");
    window.location.reload();
  };
  return (
    <Navbar fluid rounded className="mx-1">
      <Navbar.Brand href="https://flowbite-react.com">
        <NavLink to={"/"} className="flex">
          <h1 className="text-4xl font-bold text-green-600 pr-1.5">VD </h1>
          <p className="text-4xl font-bold text-red-600">
            Super<span className="text-green-600">mart</span>
          </p>
        </NavLink>
      </Navbar.Brand>
      <div className="flex md:order-2">
        <NavLink to={"/cart"}>
          <Badge
            color="danger"
            content={cart.length}
            isInvisible={isInvisible}
            shape="circle"
          >
            <BsCartFill className="w-7 h-7 " />
          </Badge>
        </NavLink>
        {user ? (
          <Dropdown
            arrowIcon={false}
            inline
            label={
              <Avatar
                alt="User settings"
                img="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
                rounded
              />
            }
          >
            <Dropdown.Header>
              <span className="block text-sm">Bonnie Green</span>
              <span className="block truncate text-sm font-medium">
                name@flowbite.com
              </span>
            </Dropdown.Header>
            <Dropdown.Item>Dashboard</Dropdown.Item>
            <Dropdown.Item>Settings</Dropdown.Item>
            <Dropdown.Item>Earnings</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={logoutHandler}>Sign out</Dropdown.Item>
          </Dropdown>
        ) : (
          <>
            <Button
              gradientDuoTone="tealToLime"
              onClick={() => navigation("login")}
              className="mx-1"
            >
              Login
            </Button>
            <Button
              gradientDuoTone="pinkToOrange"
              onClick={() => navigation("register")}
              className="ml-1"
            >
              Sign Up
            </Button>
          </>
        )}

        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        <Dropdown arrowIcon={true} inline label="Shop by Category">
          <Dropdown.Item>Fruits & Vegetables</Dropdown.Item>
          <Dropdown.Item>Foodgrains, Oil & Masala</Dropdown.Item>
          <Dropdown.Item>Beverages</Dropdown.Item>
        </Dropdown>
        <NavLink
          to={"/products"}
          className={({ isActive }) =>
            isActive ? "text-blue-950 font-semibold" : "text-black"
          }
        >
          Fresh Fruits
        </NavLink>
        <NavLink
          to={"/contact"}
          className={({ isActive }) =>
            isActive ? "text-blue-950 font-semibold" : "text-black"
          }
        >
          Masalas & Spices
        </NavLink>
        <NavLink
          to={"/contact"}
          className={({ isActive }) =>
            isActive ? "text-blue-950 font-semibold" : "text-black"
          }
        >
          Dals & Pulses
        </NavLink>
        <NavLink
          to={"/contact"}
          className={({ isActive }) =>
            isActive ? "text-blue-950 font-semibold" : "text-black"
          }
        >
          Atta, Flours & Sooji
        </NavLink>
      </Navbar.Collapse>
    </Navbar>
    // <>
    //   <Navbar maxWidth="full" className="bg-[#fdf0d5]">
    //     <NavbarBrand>
    //       <NavLink to={"/"} className="flex">
    //         <h1 className="text-4xl font-bold text-green-600 pr-1.5">VD </h1>
    //         <p className="text-4xl font-bold text-red-600">
    //           Super<span className="text-green-600">mart</span>
    //         </p>
    //       </NavLink>
    //     </NavbarBrand>

    //     <NavbarContent className="hidden sm:flex gap-4" justify="center">
    //       <Dropdown>
    //         <NavbarItem className="bg-green-600 py-0 rounded-lg">
    //           <DropdownTrigger>
    //             <Button
    //               disableRipple
    //               className="px-5 bg-transparent data-[hover=true]:bg-transparent text-slate-50 flex items-center"
    //               radius="sm"
    //               variant="light"
    //             >
    //               Shop by Category
    //               <BsCaretDownFill className="pt-1 py-0 my-0" />
    //             </Button>
    //           </DropdownTrigger>
    //         </NavbarItem>
    //         <DropdownMenu
    //           aria-label="ACME features"
    //           itemClasses={{
    //             base: "gap-4",
    //           }}
    //         >
    //           <DropdownItem key="autoscaling">Fruits & Vegetables</DropdownItem>
    //           <DropdownItem key="usage_metrics">
    //             Foodgrains, Oil & Masala
    //           </DropdownItem>
    //           <DropdownItem key="production_ready">Beverages</DropdownItem>
    //         </DropdownMenu>
    //       </Dropdown>
    //       <NavbarItem>
    //         <NavLink
    //           to={"/about"}
    //           className={({ isActive }) =>
    //             isActive ? "text-blue-950 font-semibold" : "text-black"
    //           }
    //         >
    //           Fresh Vegetables
    //         </NavLink>
    //       </NavbarItem>
    //       <NavbarItem>
    //         <NavLink
    //           to={"/products"}
    //           className={({ isActive }) =>
    //             isActive ? "text-blue-950 font-semibold" : "text-black"
    //           }
    //         >
    //           Fresh Fruits
    //         </NavLink>
    //       </NavbarItem>
    //       <NavbarItem>
    //         <NavLink
    //           to={"/contact"}
    //           className={({ isActive }) =>
    //             isActive ? "text-blue-950 font-semibold" : "text-black"
    //           }
    //         >
    //           Masalas & Spices
    //         </NavLink>
    //       </NavbarItem>
    //       <NavbarItem>
    //         <NavLink
    //           to={"/contact"}
    //           className={({ isActive }) =>
    //             isActive ? "text-blue-950 font-semibold" : "text-black"
    //           }
    //         >
    //           Dals & Pulses
    //         </NavLink>
    //       </NavbarItem>
    //       <NavbarItem>
    //         <NavLink
    //           to={"/contact"}
    //           className={({ isActive }) =>
    //             isActive ? "text-blue-950 font-semibold" : "text-black"
    //           }
    //         >
    //           Atta, Flours & Sooji
    //         </NavLink>
    //       </NavbarItem>
    //     </NavbarContent>

    //     <NavbarContent as="div" justify="end">
    //       <NavbarItem>
    //         <NavLink to={"/cart"}>
    //           <Badge
    //             color="danger"
    //             content={cart.length}
    //             isInvisible={isInvisible}
    //             shape="circle"
    //           >
    //             <BsCartFill className="w-7 h-7 " />
    //           </Badge>
    //         </NavLink>
    //       </NavbarItem>

    //       {user ? (
    //         <Dropdown placement="bottom-end">
    //           <DropdownTrigger>
    //             <Avatar
    //               isBordered
    //               as="button"
    //               className="transition-transform"
    //               color="secondary"
    //               name="Jason Hughes"
    //               size="sm"
    //               src="https://i.pravatar.cc/150?u=a042581f4e29026704d"
    //             />
    //           </DropdownTrigger>
    //           <DropdownMenu aria-label="Profile Actions" variant="flat">
    //             <DropdownItem key="profile" className="">
    //               <p className="text-xs">Signed in as</p>
    //               <p className="font-bold">Jagadeesh Peetla</p>
    //             </DropdownItem>
    //             <DropdownItem key="settings">My Settings</DropdownItem>
    //             <DropdownItem key="team_settings">My Orders</DropdownItem>
    //             <DropdownItem key="analytics">Saved Address</DropdownItem>
    //             <DropdownItem key="system">Notifications</DropdownItem>
    //             <DropdownItem key="configurations">Reviews</DropdownItem>
    //             <DropdownItem key="help_and_feedback">Help Center</DropdownItem>
    //             <DropdownItem
    //               key="logout"
    //               color="danger"
    //               onClick={logoutHandler}
    //             >
    //               Log Out
    //             </DropdownItem>
    //           </DropdownMenu>
    //         </Dropdown>
    //       ) : (
    //         <>
    //           <NavbarItem className="hidden lg:flex">
    //             <Button
    //               as={Link}
    //               color="primary"
    //               href="login"
    //               variant="flat"
    //               // onPress={() => LoginHandler()}
    //             >
    //               Login
    //             </Button>
    //           </NavbarItem>
    //           <NavbarItem>
    //             <Button
    //               as={Link}
    //               color="danger"
    //               href="register"
    //               variant="flat"
    //               // onPress={() => SignupHandler()}
    //             >
    //               Sign Up
    //             </Button>
    //           </NavbarItem>
    //         </>
    //       )}
    //     </NavbarContent>
    //   </Navbar>
    //   <Modal isOpen={isOpen} onOpenChange={onOpenChange} placement="top-center">
    //     <ModalContent>
    //       {(onClose) => (
    //         <>
    //           <ModalHeader className="flex flex-col gap-1">
    //             {login ? "Log in" : "Sign Up"}
    //           </ModalHeader>
    //           <ModalBody>
    //             {login ? (
    //               <>
    //                 <Input
    //                   autoFocus
    //                   endContent={
    //                     <MailIcon className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
    //                   }
    //                   label="Email"
    //                   placeholder="Enter your email"
    //                   variant="bordered"
    //                 />
    //                 <Input
    //                   endContent={
    //                     <LockIcon className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
    //                   }
    //                   label="Password"
    //                   placeholder="Enter your password"
    //                   type="password"
    //                   variant="bordered"
    //                 />
    //                 <div className="flex py-2 px-1 justify-between">
    //                   <Checkbox
    //                     classNames={{
    //                       label: "text-small",
    //                     }}
    //                   >
    //                     Remember me
    //                   </Checkbox>
    //                   <Link color="primary" href="#" size="sm">
    //                     Forgot password?
    //                   </Link>
    //                 </div>
    //               </>
    //             ) : (
    //               <>
    //                 <Input
    //                   autoFocus
    //                   endContent={
    //                     <PhoneIcon className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
    //                   }
    //                   label="Fullname"
    //                   placeholder="Enter your fullname"
    //                   variant="bordered"
    //                 />
    //                 <Input
    //                   autoFocus
    //                   endContent={
    //                     <MailIcon className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
    //                   }
    //                   label="Email"
    //                   placeholder="Enter your email"
    //                   variant="bordered"
    //                 />
    //                 <Input
    //                   endContent={
    //                     <LockIcon className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
    //                   }
    //                   label="Password"
    //                   placeholder="Enter your password"
    //                   type="password"
    //                   variant="bordered"
    //                 />
    //               </>
    //             )}
    //           </ModalBody>
    //           <ModalFooter>
    //             <Button color="danger" variant="flat" onPress={onClose}>
    //               Close
    //             </Button>
    //             <Button color="primary" onPress={onClose}>
    //               {login ? "Sign in" : "Sign Up"}
    //             </Button>
    //           </ModalFooter>
    //         </>
    //       )}
    //     </ModalContent>
    //   </Modal>
    // </>
  );
};

export default Header;
