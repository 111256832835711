import React, { useState } from "react";
import { Button, Checkbox, Label, TextInput } from "flowbite-react";
import Loinimg from "../assets/log.png";
import { useNavigate } from "react-router";

const Login = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    phone: "",
    password: "",
  });
  const loginhandler = () => {
    if (user.phone === "" && user.password === "") {
      alert("Please login!!!");
    } else {
      localStorage.setItem("user", true);
      navigate("/");
    }
  };
  return (
    <div className="bg-gradient-to-b from-[#30cfd0]  to-[#330867] h-screen">
      <div className=" grid grid-cols-8 gap-4 place-content-center py-5 absolute top-0 bottom-0 left-0 right-0">
        <div className="col-start-3 col-span-4 flex">
          <div className="bg-slate-50 rounded-l-xl p-5">
            <h1 className="font-bold text-center">
              <span className="text-sm italic">Welcome to</span> <br />
              <span className="text-3xl text-green-700">VD</span>{" "}
              <span className="text-3xl text-red-700 pl-1">
                Super<span className="text-green-700">mart</span>
              </span>
            </h1>
            <img src={Loinimg} alt="" className="w-80 py-2.5 opacity-75" />
            <div className="flex justify-center items-center gap-2 pt-3.5">
              <Label htmlFor="member">Not a member?</Label>
              <Label
                htmlFor="sign"
                className="text-green-600 cursor-pointer"
                onClick={() => navigate("/register")}
              >
                Sign Up
              </Label>
            </div>
          </div>
          <div className="bg-red-100 rounded-r-xl p-5">
            <h1 className="text-xl font-bold">Sign In</h1>
            <form className="flex max-w-md flex-col gap-4 w-80 py-3.5">
              <div>
                <div className="mb-0.5 block">
                  <Label htmlFor="phone" value="Mobile no" />
                </div>
                <TextInput
                  id="phone"
                  value={user.phone}
                  type="mobile"
                  placeholder="Enter mobile no"
                  onChange={(e) => setUser({ ...user, phone: e.target.value })}
                  required
                />
              </div>
              <div>
                <div className="mb-0.5 block">
                  <Label htmlFor="password" value="Password" />
                </div>
                <TextInput
                  id="password"
                  type="password"
                  value={user.password}
                  onChange={(e) =>
                    setUser({ ...user, password: e.target.value })
                  }
                  required
                  placeholder="Enter password"
                />
              </div>
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <Checkbox id="remember" />
                  <Label htmlFor="remember">Remember me</Label>
                </div>
                <div className="flex items-center gap-2">
                  <Label htmlFor="forgot" className="cursor-pointer">
                    Forgot password?
                  </Label>
                </div>
              </div>
              <div className="py-2">
                <Button
                  type="submit"
                  className="w-full"
                  color="success"
                  onClick={loginhandler}
                >
                  Sign In
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
