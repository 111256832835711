import React from "react";
import { Outlet } from "react-router";

const AuthenticationLayout = () => {
  return (
    <div className="bg-gradient-to-b from-indigo-500 via-purple-500 to-pink-500">
      <Outlet />
    </div>
  );
};

export default AuthenticationLayout;
