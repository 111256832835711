import React, { useState } from "react";
import Header from "./Header";
import { Outlet } from "react-router";
import Footerv from "../pages/Footerv";

const Layout = () => {
  return (
    <div className="">
      <Header />
      <Outlet />
      <Footerv />
    </div>
  );
};

export default Layout;
